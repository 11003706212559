@import "../../sass/theme/constants";
@import "../../sass/theme/fonts";
@import "../../sass/theme/mixins";
@import "../../sass/utils/mixins";

.surf-dropdown {

    &.readonly {
        .surf-select__control {
            border: none !important;
            height: auto;
            padding: 10px 0;

            .surf-select__indicators {
                display: none;
            }
        }
    }

    .surf-select__control {
        @include rounded-background-pointy-upper-left($background-color);
        height: 50px;
        cursor: pointer;
        min-width: 130px;
        min-height: initial;
        padding: 10px 15px 10px 10px;
        border: 1px solid $input-border-color;

        .surf-select__value-container {
            padding: 0;

            .surf-select__placeholder {
                @include open-sans;
                font-size: 12px;
                color: $text-color;
            }

            .surf-select__custom-select-container {
                display: flex;

                .surf-select__icon-wrapper {
                    padding-left: 5px;
                    padding-right: 5px;

                    .surf-select__custom-select-icon path {
                        fill: $text-color;
                    }
                }

                .surf-select__text-wrapper {
                    @include open-sans;
                    font-size: 12px;
                    color: $text-color;
                }
            }
        }

        .surf-select__indicator {
            color: $text-color;
            padding: 0;

            .svg-inline--fa {
                height: 0.7em;
            }
        }
    }

    .surf-select__menu {
        border-radius: 0 0 $default-radius $default-radius;
        margin-top: 6px;
        margin-bottom: 0;

        .surf-select__menu-list {
            padding-top: 0;
            padding-bottom: 0;

            .surf-select__option {
                @include open-sans;
                font-size: 12px;
                color: $text-color;
                cursor: pointer;

                &.surf-select__option--is-focused {
                    background-color: rgba(lighten($majorelle, 20%), 0.3);
                    color: $majorelle;
                }

                &.surf-select__option--is-selected {
                    background-color: rgba($majorelle, 0.3);
                    color: $majorelle;
                }
            }

            .surf-select__option:last-child {
                border-bottom-left-radius: $default-radius;
                border-bottom-right-radius: $default-radius;
            }
        }
    }
}