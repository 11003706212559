@import "../../../sass/theme/constants";
@import "../../../sass/theme/fonts";
@import "../../../sass/theme/mixins";
@import "../../../sass/utils/mixins";
@import "../../../sass/mediaquery/mediaquery";

.react-table-done-row {
    tbody {
        & td:first-child {
            & div {
               margin-top: 5px;
            }
        }

        & td:nth-child(-n+3) {
            text-decoration: line-through $grayStatus !important;
            text-decoration-color: $grayStatus;
            color: $grayStatus;

            & div:hover {
                text-decoration: line-through $grayStatus !important;
                text-decoration-color: $grayStatus;
                color: $grayStatus;
            }
        }


        & td:first-child::before {
            float: left;
            margin-right: 15px;
            margin-top: 4px;
            content: url("../../../resources/icons/majorelle_checkmark.svg");
        }

        & td:nth-child(4) {
            div {
                color: $grayStatus;
            }
        }
    }
}

.react-table-seperated-row {
    border-spacing: 0 10px !important;

    tbody {
        & td {
            border-bottom: none;
            padding-top: 11px !important;
            padding-bottom: 11px !important;
        }

        & td:first-child {
            padding-left: 20px !important;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 15px;
        }

        & td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        & tr {
            height: 60px;
            cursor: default !important;
        }
    }

    thead {
        & th {
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }
        & th:first-child {
            padding-left: 20px !important;
        }
        & th:last-child {
            padding-left: 35px !important;
        }
    }

    tr[role=row] {
        .fa,
        .fas,
        .svg-inline--fa {
            font-size: unset !important;
        }
    }

    .info-icon-wrapper {
        font-size: 10px !important;
    }
}

.react-table {
    width: 100%;
    border-spacing: 0;

    tr[role=row] {
        @include open-sans;
        cursor: pointer;
        font-size: 12px;

        .fa,
        .fas,
        .svg-inline--fa {
            font-size: 16px;
        }
    }

    th,
    td {
        text-align: left;
    }

    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-transform: uppercase;
        font-size: 12px;

        .border {
            position: relative;
            border-left: 1px solid #D2D2D2;
            padding-left: 16px;
        }

        .border-no-translation {
            border-left: 1px solid #D2D2D2;
        }
    }

    tbody {
        background-color: white;
    }

    /* Border */
    td {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 12px;
        padding-left: 16px;
        border-bottom: 1px solid $grayLight;
    }

    tbody tr:last-child td {
        border-bottom: none;
    }

    /* Padding left */
    tbody tr td:first-child {
        padding-left: 20px;
    }

    /* Padding right */
    tbody tr td:last-child {
        padding-right: 32px;
    }

    /* Corner radius */
    tbody tr:first-child td:first-child {
        border-top-left-radius: 2px;
    }

    tbody tr:first-child td:last-child {
        border-top-right-radius: 15px;
    }

    &.without-footer {
        tbody tr:last-child td:first-child {
            border-bottom-left-radius: 15px;
        }

        tbody tr:last-child td:last-child {
            border-bottom-right-radius: 15px;
        }
    }

    tfoot {
        background-color: white;

        td {
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 15px;
            border-top: 1px solid $grayLight;
            border-bottom: 0;
            height: 60px;

            .footer-holder {
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
                align-content: center;
            }
        }
    }

    /* Cell styling */
    td[role=cell] {

        &.bold-text {
            @include open-sans-bold
        }

        .profile-banner-image {
            margin-right: 0;
        }

        .profile-banner-image.placeholder,
        .document-icon-cell,
        .group-icon-cell {
            background-color: $cultured;
            color: $majorelle !important;
            padding: 8px;
            border-radius: 4px;
        }

        .status-label-wrapper {
            display: flex;

            .status-label-container {
                @include open-sans-bold();
                display: flex;
                align-items: center;
                background-color: $cultured;
                min-width: 140px;
                max-width: 140px;
                border-radius: 5px;

                .status-label-indicator {
                    margin-left: 9px;
                    min-width: 9px;
                    min-height: 9px;
                    border-radius: 9px;
                    background-color: #899194;
                }

                .status-label-text {
                    padding-top: 2px;
                    padding-bottom: 2px;
                    margin-left: 9px;
                    margin-right: 9px;
                    text-transform: uppercase;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 12px;
                }
            }
        }

        .cell.action-cell .fas {
            cursor: pointer;

            &.fa-trash.delete-icon {
                margin-left: 20px;
            }
        }
    }
}

.react-table-loading-indicator {
    @include rounded-background-pointy-upper-left();
    @include open-sans-bold();
    background-color: white;
    display: flex;
    flex-direction: column;
    color: $text-color;
    font-size: 12px;
    padding-top: 40px;
    padding-bottom: 40px;

    .loading-subtitle {
        flex-grow: 1;
        text-align: center;
        margin-top: 30px;
    }
}

.react-table-empty-list {
    @include rounded-background-pointy-upper-left();
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;

    .empty-list-title {
        @include nunito-extra-bold();
        font-size: 25px;
        margin-top: 30px;
    }

    .empty-list-subtitle {
        @include nunito-bold();
        font-size: 12px;
        margin-top: 10px;
    }

    .surf-icon-button-text {
        margin-top: 20px;
    }
}

.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;

    > *:not(:first-child) {
        margin-left: 4px;
    }

    .previous {
        margin-right: 6px;
        cursor: pointer;
    }

    .next {
        margin-left: 10px;
        cursor: pointer;
    }

    .pagination-number {
        @include rounded-background-pointy-upper-left($space-cadet, 5px);
        color: white;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        @include open-sans-bold;
        user-select: none;

        &.current {
            background-color: $majorelle;
            cursor: pointer;
        }

        &.clickable:hover {
            background-color: $majorelle-light;
            cursor: pointer;
        }
    }
}