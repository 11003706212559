@import "../sass/theme/constants";
@import "../sass/theme/fonts";
@import "../sass/theme/mixins";
@import "../sass/utils/mixins";
@import "../sass/grid/grid_mixins";
@import "../sass/grid/z-span";
@import "../resources/fontawesome/css/all.css";

#dashboard {
    @include background-center-crop;
    position: relative;
    background-repeat: no-repeat;

    .notifications-container {
        i.fas.fa-bell {
            color: white;
        }
    }

    .dark-gradient-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($space-cadet, 0.6);
    }

    .half-page-gradient {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0deg, rgba(#F8F8F8, 1), rgba(#F8F8F8, 1), rgba(#F8F8F8, 1), rgba(#F8F8F8, 0.75), rgba(#F8F8F8, 0.0));
    }

    .title-row {
        @include open-sans;
        color: white;
        font-size: 40px;
        line-height: 55px;
        padding-top: 25px;
        padding-bottom: 25px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;

        .search-count {
            display: flex;
            margin-left: 16px;
            font-size: 12px;
            color: white;
            width: 34px;
            height: 34px;
            align-items: center;
            justify-content: center;
            @include open-sans-bold;
            @include rounded-background-pointy-upper-left($space-cadet, 8px);
        }

        &.secondary {
            color: $space-cadet;
            padding-bottom: 0;
            margin-bottom: 15px;
        }

        .black {
            @include nunito-black;
        }
    }

    .card-row {
        @include clear-after;
        display: flex;

        @include media-query-desktop {
            flex-direction: row;
            justify-content: center;
        }

        @include media-query-mobile-tablet {
            flex-direction: column;
            align-items: center;
        }

        .card {
            margin-bottom: 20px;
            overflow: hidden;

            @include media-query-desktop {
                @include z-span(4);
                &.card:nth-child(3) {
                    @include z-span-last
                }
            }

            @include media-query-mobile-tablet {
                @include z-span-full;
            }

            @include media-query-max(1400px) {
                h2 {
                    font-size: 34px;
                }
            }

            @media only screen and (max-width: 1400px)  {
                h2 {
                    font-size: 28px;
                }
            }

            .share {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-left: 10px;
                padding-top: 10px;
                padding-right: 10px;

                @media only screen and (max-width: 1400px)  {
                    padding-left: 0;
                    padding-right: 0;
                }

                .icon-button-publications {
                    z-index: 1;
                }

                .icon-button-profile {
                    z-index: 1;
                }

                .icon-publications {
                    position: absolute;
                    right: -20px;
                    bottom: -50px;
                }

                h2 {
                    z-index: 1;
                    color: white;
                }

                .surf-button-text {
                    z-index: 1;
                }
            }

            .explanation {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .explanation-text {
                    font-size: 12px;
                    padding-bottom: 30px;
                }

                .surf-button-text .surf-button-text-container.primary {
                    background: white;
                    border: 2px solid $space-cadet;
                    color: $space-cadet;
                }
            }

            .benefits .benefits-list-item {
                display: flex;
                align-items: center;
                padding-bottom: 15px;

                .fas.fa-check {
                    color: $majorelle;
                    padding-right: 15px;
                }

                .text {
                    font-size: 12px;
                }
            }

            .report {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }

    .author-cell {
        visibility: hidden;
    }

    .flex-reverse-row{
        margin-top: 20px;
    }
}

.overlay {
    background-color: $text-color;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
}

.hide-completed-tasks {
    visibility: hidden;
}