@import "../sass/theme/constants";
@import "../sass/theme/mixins";
@import "../sass/utils/mixins";

.expandable-list {
    margin-top: 30px;

    .expandable-row-container {
        position: relative;

        &:last-child {
            > .parent-relationship-line {
                bottom: calc(100% - (52px / 2) /* half row height */ - 11px /* top offset */);
            }
        }

        > .parent-relationship-line {
            position: absolute;
            z-index: 0;
            background: $grayMedium;
            width: 1px;
            top: -11px;
            bottom: 0;
            left: -19px;
        }

        .expandable-row {
            @include open-sans;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            border: 1px solid $cultured;
            border-radius: 5px;
            background-color: white;
            min-height: 52px;
            padding: 13px 50px 13px 30px;
            margin-bottom: 9px;
            font-size: 12px;

            .child-relationship-line {
                position: absolute;
                z-index: 10;
                background: $grayMedium;
                height: 1px;
                width: 19px;
                left: -19px;

                .line-node {
                    transform: translate(-3px, -3px);
                    width: 7px;
                    height: 7px;
                    border-radius: 7px;
                    border: 1px solid $grayMedium;
                    background: white;
                }
            }

            .status-color-indicator {
                position: absolute;
                background-color: $majorelle;
                width: 7px;
                height: 100%;
                left: 0;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;

                &.inactive {
                    background-color: $grayDark
                }
            }

            &.consortium {
                .status-color-indicator {
                    background-color: $ocean-green-light;
                }

                .icon-organisation-wrapper {
                    svg path {
                        fill: $ocean-green-light;
                    }
                }
            }

            .row-information {
                display: flex;
                align-items: center;

                .icon-chevron {
                    font-size: 13px;
                    min-width: 12px;
                }

                .icon-organisation-wrapper {
                    background-color: $cultured;
                    padding: 10px;
                    border-radius: 4px;
                    margin-left: 15px;
                    margin-right: 15px;

                    svg.inactive path {
                        fill: $grayDark;
                    }
                }

                .row-text {
                    @include max-number-of-lines(2);
                    margin-right: 15px;
                }
            }

            .right-row-section {
                display: flex;
                align-items: center;
                justify-content: center;

                .row-actions {
                    display: flex;

                    .fa-trash,
                    .fa-edit,
                    .fa-plus,
                    .fa-toggle-on,
                    .fa-toggle-off {
                        cursor: pointer;

                        &.disabled {
                            color: $grayDark;
                            cursor: default;
                        }
                    }

                    .fa-trash,
                    .fa-edit,
                    .fa-toggle-on,
                    .fa-toggle-off {
                        margin-right: 12px
                    }
                }
            }
        }

        .child-rows {
            margin-left: 25px;
            
            .expandable-row-loading-indicator {
                padding-bottom: 9px;

                .loading-indicator img {
                    width: 30px;
                }

                .loading-subtitle {
                    @include open-sans-bold();
                    flex-grow: 1;
                    text-align: center;
                    margin-top: 5px;
                    font-size: 12px;
                }
            }
        }
    }
}

.expandable-list-loading-indicator {
    @include rounded-background-pointy-upper-left();
    @include open-sans-bold();
    background-color: white;
    display: flex;
    flex-direction: column;
    color: $text-color;
    font-size: 12px;
    padding-top: 40px;
    padding-bottom: 40px;

    .loading-subtitle {
        flex-grow: 1;
        text-align: center;
        margin-top: 30px;
    }
}